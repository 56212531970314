.image-wrapper {
  position: relative;
  border-radius: inherit;
}

img {
  max-width: 100%;
  object-fit: cover;
  border-radius: inherit;
  height: 400px;

  position: absolute;
  left: 0;

  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.placeholder {
  z-index: 999;
}

.transparant {
  opacity: 0;
}
