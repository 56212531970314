@font-face {
  font-family: "Caveat";
  src: url(/static/media/Caveat-Bold.3001cff3.ttf) format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}

@font-face {
  font-family: "Simonetta";
  src: url(/static/media/Simonetta-Regular.e2ff5d10.ttf) format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}

@font-face {
  font-family: "Karla";
  src: url(/static/media/Karla-Regular.1b55fee6.ttf) format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Karla", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.image-wrapper {
  position: relative;
  border-radius: inherit;
}

img {
  max-width: 100%;
  object-fit: cover;
  border-radius: inherit;
  height: 400px;

  position: absolute;
  left: 0;
  transition: opacity 1s ease-in-out;
}

.placeholder {
  z-index: 999;
}

.transparant {
  opacity: 0;
}

@font-face {
  font-family: "icomoon";
  src: url(/static/media/icomoon.0b28c696.eot);
  src: url(/static/media/icomoon.0b28c696.eot#iefix)
      format("embedded-opentype"),
    url(/static/media/icomoon.ef046560.ttf) format("truetype"),
    url(/static/media/icomoon.360ac704.woff) format("woff"),
    url(/static/media/icomoon.a5ad1968.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
  content: "\EA92";
}
.icon-twitter:before {
  content: "\EA96";
}
.icon-github:before {
  content: "\EAB0";
}
.icon-linkedin:before {
  content: "\EACA";
}

.social-block {
  width: calc(50vw - 20px);
  max-width: 190px;
  height: calc(50vw - 20px);
  max-height: 190px;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;

  font-size: 8em;
  color: #fff;
  background-color: rgb(0, 0, 0);

  margin-bottom: 20px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.7);
}

.social-block-instagram {
  background-color: rgba(225, 48, 108);

  box-shadow: 0 20px 50px rgba(225, 48, 108, 0.7);
}

.social-block-twitter {
  background-color: rgba(29, 161, 242);

  box-shadow: 0 20px 50px rgba(29, 161, 242, 0.7);
}

.social-block-linkedin {
  background-color: rgba(40, 103, 178);

  box-shadow: 0 20px 50px rgba(40, 103, 178, 0.7);
}

.app {
  text-align: center;
}

.app-header,
.app-footer {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(16px + 2vmin); */
}

.signature {
  height: 120px;
  margin: 1.3em 0;
}

.signature .title {
  height: 72px;
  font-size: 3.5em;
  font-family: "Caveat", sans-serif;
}

.signature .subtitle {
  font-size: 2em;
  font-family: "Simonetta", sans-serif;
}

.me-image {
  width: 100%;
  max-width: 400px;
  height: 400px;

  border-radius: 5px;
  box-shadow: 0 20px 50px rgba(127, 145, 131, 0.7);
}

.social-grid {
  max-width: 400px;
  /*width: 250px;*/
  height: 400px;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

