.app {
  text-align: center;
}

.app-header,
.app-footer {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(16px + 2vmin); */
}

.signature {
  height: 120px;
  margin: 1.3em 0;
}

.signature .title {
  height: 72px;
  font-size: 3.5em;
  font-family: "Caveat", sans-serif;
}

.signature .subtitle {
  font-size: 2em;
  font-family: "Simonetta", sans-serif;
}

.me-image {
  width: 100%;
  max-width: 400px;
  height: 400px;

  border-radius: 5px;
  box-shadow: 0 20px 50px rgba(127, 145, 131, 0.7);
}

.social-grid {
  max-width: 400px;
  /*width: 250px;*/
  height: 400px;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
