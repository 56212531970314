@font-face {
  font-family: "Caveat";
  src: url("./assets/fonts/caveat/Caveat-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}

@font-face {
  font-family: "Simonetta";
  src: url("./assets/fonts/simonetta/Simonetta-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}

@font-face {
  font-family: "Karla";
  src: url("./assets/fonts/karla/Karla-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Karla", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
