@font-face {
  font-family: "icomoon";
  src: url("../../assets/icons/iconmoon/icomoon.eot?f0g0ot");
  src: url("../../assets/icons/iconmoon/icomoon.eot?f0g0ot#iefix")
      format("embedded-opentype"),
    url("../../assets/icons/iconmoon/icomoon.ttf?f0g0ot") format("truetype"),
    url("../../assets/icons/iconmoon/icomoon.woff?f0g0ot") format("woff"),
    url("../../assets/icons/iconmoon/icomoon.svg?f0g0ot#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-github:before {
  content: "\eab0";
}
.icon-linkedin:before {
  content: "\eaca";
}

.social-block {
  width: calc(50vw - 20px);
  max-width: 190px;
  height: calc(50vw - 20px);
  max-height: 190px;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;

  font-size: 8em;
  color: #fff;
  background-color: rgb(0, 0, 0);

  margin-bottom: 20px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.7);
}

.social-block-instagram {
  background-color: rgba(225, 48, 108);

  box-shadow: 0 20px 50px rgba(225, 48, 108, 0.7);
}

.social-block-twitter {
  background-color: rgba(29, 161, 242);

  box-shadow: 0 20px 50px rgba(29, 161, 242, 0.7);
}

.social-block-linkedin {
  background-color: rgba(40, 103, 178);

  box-shadow: 0 20px 50px rgba(40, 103, 178, 0.7);
}
